import { Controller } from "@hotwired/stimulus"
import { get, post } from "@rails/request.js"
import * as WebAuthnJSON from "@github/webauthn-json"

export default class extends Controller {
  static targets = ["supported", "unsupported"]
  static values = { url: String }

  connect() {
    if (this.#isSupported() && this.hasSupportedTarget) {
      this.supportedTarget.classList.remove("hidden")
    } else if(this.hasUnsupportedTarget) {
      this.unsupportedTarget.classList.remove("hidden")
    }
  }

  enable() {
    get(this.urlValue)
      .then(async res => WebAuthnJSON.create({ publicKey: await res.json }))
      .then(credential => {
        return post(this.urlValue, { body: JSON.stringify(credential) })
      })
      .then(res => {
        Turbo.visit(location.href, { action: "replace" })
      })
  }

  confirmTwoFactor(event) {
    if (this.element.querySelector(`[name="two_factor_confirmation_token"]`)) {
      return
    }

    event.preventDefault()

    get(this.urlValue)
      .then(async res => WebAuthnJSON.get({ publicKey: await res.json }))
      .then(credential => {
        return post(this.urlValue, { body: JSON.stringify(credential) })
      })
      .then(async res => {
        const { token } = await res.json

        this.element.insertAdjacentHTML('beforeend', `
          <input type="hidden" name="two_factor_confirmation_token" value="${token}" />
        `)

        this.element.requestSubmit()
      })
  }

  #isSupported() {
    return typeof PublicKeyCredential !== "undefined"
  }
}
