import { Controller } from "@hotwired/stimulus";

const OFFSET_FROM_CURSOR_PX = 20;

export default class extends Controller {
  static targets = ["source"]

  copy(event) {
    navigator.clipboard.writeText(this.sourceTarget.value)

    const confirmationToast = document.createElement('div')
    confirmationToast.className = "fixed bg-black rounded-full text-white text-sm py-0.5 px-2"
    confirmationToast.innerText = "✓ Copied"
    confirmationToast.style.top = event.clientY + "px"
    confirmationToast.style.left = event.clientX + OFFSET_FROM_CURSOR_PX + "px"

    document.body.insertAdjacentElement('beforeend', confirmationToast)

    setTimeout(() => {
      confirmationToast.remove()
    }, 500)
  }
}
