import { Controller } from "@hotwired/stimulus"
import { renderTemplate } from "../helpers/template_helpers"

const DYNAMIC_INPUT_ITEM_SELECTOR = "[data-index]"

export default class extends Controller {
  static values = {
    output : String,
    dynamicInputListTemplate: String
  }

  connect() {
    this.sync()
  }

  sync() {
    if (this.hasDynamicInputListTemplateValue) {
      this.#syncDynamicInputList()
    } else {
      this.#syncSingleInput()
    }
  }

  #syncDynamicInputList() {
    let html = ""
    const template = this.#dynamicInputListTemplateElement().innerHTML

    for (const item of this.element.querySelectorAll(DYNAMIC_INPUT_ITEM_SELECTOR)) {
      const vars = {}

      for (const input of item.querySelectorAll("input")) {
        vars[inputBasename(input.name)] = input.value
      }

      html += renderTemplate(template, vars)
    }

    this.#outputElement().innerHTML = html
  }

  #syncSingleInput() {
    let value = this.element.value

    if (value && this.element instanceof HTMLSelectElement) {
      const option = this.element.querySelector(`option[value="${value}"]`)

      value = option?.innerText
    }

    this.#outputElement().innerHTML = value
  }

  #outputElement() {
    return document.querySelector(this.outputValue)
  }

  #dynamicInputListTemplateElement() {
    return document.querySelector(this.dynamicInputListTemplateValue)
  }
}

function inputBasename(inputName) {
  const parts = inputName.split("[")
  const name = parts[parts.length-1].replace("]", "")

  return name
}
