import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { debounce } from "../helpers/timing_helpers"

export default class extends Controller {
  static targets = ["input", "datalist"]
  static values = { url: String }

  connect() {
    this.debouncedUpdateDatalistWithNewOptions = debounce(this.#updateDatalistWithNewOptions.bind(this), 300)
  }

  update() {
    this.debouncedUpdateDatalistWithNewOptions()
  }

  async #updateDatalistWithNewOptions() {
    const data = await get(this.urlValue + "?term=" + this.inputTarget.value)
    const { words } = await data.json
    const wordsOptions = words.map(word => `<option>${word}</option>`)

    this.datalistTarget.innerHTML = wordsOptions
  }
}