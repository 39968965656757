import { Controller } from "@hotwired/stimulus"

let permanentElementsIdsFromPreviousPage = null

document.addEventListener("turbo:before-visit", async () => {
  permanentElementsIdsFromPreviousPage = Array.from(document.querySelectorAll("[data-turbo-permanent]")).map(elm => elm.id)
})

export default class extends Controller {
  refreshIfStalePermanent() {
    if (! permanentElementsIdsFromPreviousPage) return;

    if (! permanentElementsIdsFromPreviousPage.includes(this.element.id)) {
      this.reload()
    }
  }

  reload() {
    this.element.closest("turbo-frame").reload()
  }
}
