export function setQueryParams(urlString, params) {
  const url = new URL(urlString)
  const search = new URLSearchParams(url.search)

  for (const name in params) {
    if (search.has(name)) {
      search.delete(name)
    }

    search.append(name, params[name])
  }

  url.search = search

  return url.toString()
}