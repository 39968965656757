import { post } from "@rails/request.js"

/**
 * List of behaviours:
 * 
 * 1. Show latest suggestion on load.
 * 2. After a new customer message is received, show completion when it arrives via websocket.
 * 3. After partial typing, request completion and wait for it to arrive via websocket (or response?).
 * 4. Sync completion usefulness. Start on "ignored", change to "accepted" when user presses tab, change to "accepted_with_change" when makes further edits.
 *    This should be sent alongside the message so the server can keep track of this.
 */
export class Copilot {
  #trixEditor
  #completion
  #completionsUrl

  constructor({ trixEditor, completionsUrl }) {
    this.#trixEditor = trixEditor
    this.#completionsUrl = completionsUrl

    this.#trixEditor.element.addEventListener("keydown", (ev) => {
      if (ev.key == " " && ev.ctrlKey && !this.isShowingCompletion()) {
        const body = JSON.stringify({
          prefix: this.#trixEditor.getDocument().toString()
        })

        post(this.#completionsUrl, { body })

        return
      }

      if (!this.isShowingCompletion()) {
        return
      }

      if (event.key == "Tab") {
        ev.preventDefault()

        this.#acceptCompletion()

        return
      }

      // If user starts typing it should ignore and override the completion
      if (this.#completion?.attachment && ev.key.match(/^[\p{L}!?,\.#$%&]$/ui)) {
        this.#ignoreCompletion()
      }
    })

    this.#trixEditor.element.addEventListener("trix-attachment-remove", (ev) => {
      const { attachment } = ev

      if (attachment == this.#completion?.attachment) {
        this.#completion.attachment = null
      }
    })
  }

  completionGenerated(completionElm) {
    this.#completion?.attachment?.remove()

    const { id, content } = completionElm.dataset

    this.#showCompletion({ id, content, status: "ignored" })
  }

  serialize() {
    if (! this.#completion) {
      return {}
    }

    return {
      copilot_completion_id: this.#completion.id,
      copilot_completion_status: this.#completion.status
    }
  }

  reset() {
    this.#completion = null
  }

  #showCompletion(completion) {
    this.#completion = completion

    this.#keepRange(() => {
      this.#trixEditor.element.addEventListener("trix-attachment-add", (event) => {
        const { attachment } = event

        this.#completion.attachment = attachment
      }, { once: true })

      this.#trixEditor.insertAttachment(
        new Trix.Attachment({ content: `<span class="text-secondary">${completion.content}</span>` })
      )
    })
  }

  #acceptCompletion() {
    this.#trixEditor.insertHTML(this.#completion.content)
    this.#completion.status = "accepted"
    this.#completion.attachment.remove()
  }

  #ignoreCompletion() {
    this.#completion.status = "ignored"
    this.#completion.attachment.remove()
  }

  #keepRange(callback) {
    const range = this.#trixEditor.getSelectedRange()

    callback()

    this.#trixEditor.setSelectedRange(range)
  }

  isShowingCompletion() {
    return this.#completion?.attachment != null
  }
}
