export function findIndices(string, regex) {
  if (typeof regex === "string") {
    regex = new RegExp(regex, "gi")
  }

  const indices = []
  let match = null

  while ((match = regex.exec(string)) != null) {
    indices.push(match.index)
  }

  return indices
}