import { Controller } from "@hotwired/stimulus";
import { renderTemplate } from "../helpers/template_helpers"

export default class extends Controller {
  static targets = ["template", "list"]
  static values = { initial: Array }

  connect() {
    if (this.hasInitialValue) {
      for(const record of this.initialValue) {
        const element = this.add()

        for (const name in record) {
          const value = record[name]
          const input = element.querySelector(`input[name*='[${name}]']`)

          if (input) {
            input.value = value
          }
        }
      }
    }
  }

  add() {
    const index    = new Date().getTime()
    const template = this.templateTarget.innerHTML
    const html     = renderTemplate(template, { index })

    this.listTarget.insertAdjacentHTML("beforeend", html)

    return Array.from(this.listTarget.querySelectorAll("[data-index]")).at(-1)
  }

  remove(ev) {
    const container = ev.currentTarget.closest("[data-index]")

    container.remove()
  }
}
