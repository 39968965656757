import { Controller } from "@hotwired/stimulus"
import { currentAccountId } from "../helpers/current_helpers"
import { nextRenderTick, keepScrollWhenScrollingDown, parseHTML } from "../helpers/dom_helpers"
import { setQueryParams } from "../helpers/url_helpers"
import { debounce } from "../helpers/timing_helpers"
import { get } from "@rails/request.js"

const ENTRIES_PER_PAGE = 15

export default class extends Controller {
  static targets = ["nudge", "nudgelist", "loadMore", "newTicketWithHigherAgony"]

  #trimExcessEnabled = true
  #nudgeTargetReactiveEnabled = false

  connect() {
    this.#nudgeTargetReactiveEnabled = true

    this.#disableTrimExcessWhenUserScrollsDown()
  }

  disconnect() {
    this.#nudgeTargetReactiveEnabled = false
  }

  realtimeReconnect() {
    const frame = this.element.closest("turbo-frame")

    const updatedUrl = setQueryParams(frame.getAttribute("src"), {
      highest_agony: this.#highestAgony(),
      limit: this.nudgeTargets.length,
      _refresh_reason: "reconnect"
    })

    frame.setAttribute("src", updatedUrl)

    frame.reload()
  }

  realtimeReappear() {
    const frame = this.element.closest("turbo-frame")

    const updatedUrl = setQueryParams(frame.getAttribute("src"), {
      highest_agony: this.#highestAgony(),
      limit: this.nudgeTargets.length,
      _refresh_reason: "reappear"
    })

    frame.setAttribute("src", updatedUrl)

    frame.reload()
  }

  newTicketWithHigherAgonyTargetConnected() {
    Telescope.Notification.show({
      title: "New ticket!",
      body: "There is new a customer waiting in your inbox."
    })
  }

  nudgeTargetConnected(nudgeElm) {
    if (! this.#nudgeTargetReactiveEnabled) return;

    const { accountId } = nudgeElm.dataset

    // NOTE: we broadcast inbox nudges to the workspace channel even through it might be targeted at one
    // specific person. When this happens, we should drop the nudge and hide it. By the way, this is not
    // a security or privacy violation because the users are all part of the same worksapce, and the
    // ticket is still visible via the search page.
    if (accountId && currentAccountId() != accountId) {
      nudgeElm.remove()

      return
    }

    this.#sortByAgony()
    this.#trimExcess()

    const nudgeHasHigherAgonyThanOthers = this.nudgeTargets.indexOf(nudgeElm) === 0
    if(nudgeHasHigherAgonyThanOthers) {
      Telescope.Notification.show({
        title: "New ticket!",
        body: "There is new a customer waiting in your inbox."
      })
    }
  }

  nudgeTargetDisconnected() {
    if (! this.#nudgeTargetReactiveEnabled) return;

    if (this.nudgeTargets.length === ENTRIES_PER_PAGE - 1) {
      this.#loadMoreNudgesWithLimit(1)
    }
  }

  loadMoreNudges() {
    this.#loadMoreNudgesWithLimit(ENTRIES_PER_PAGE)
  }

  async #loadMoreNudgesWithLimit(limit) {
    const lowestAgony = this.#lowestAgony()
    if (! lowestAgony) return;


    keepScrollWhenScrollingDown(this.#scrollableContainer(), async () => {
      const addedNudges = await this.#countAddedNudges(async () => {
        await this.#disableNudgeTargetReactive(async () => {
          const response = await get(`/inbox/ticket_picking?cursor=${lowestAgony}&limit=${limit}`)

          for (const nudge of parseHTML(await response.html).querySelectorAll("[data-nudge-id]")) {
            this.nudgelistTarget.appendChild(nudge)
          }
        })
      })

      this.#toggleLoadMore(addedNudges >= limit)
    })
  }

  #sortByAgony() {
    this.nudgeTargets
      .sort((a, b) => a.dataset.agony.localeCompare(b.dataset.agony))
      .forEach(nudge => {
        nudge.remove()

        this.nudgelistTarget.appendChild(nudge)
      })
  }

  #disableTrimExcessWhenUserScrollsDown() {
    const container = this.#scrollableContainer()

    container.addEventListener("scroll", debounce(() => {
      this.#trimExcessEnabled = container.scrollTop < 10
    }, 100))
  }

  #trimExcess() {
    if (! this.#trimExcessEnabled) return;

    const length = this.nudgeTargets.length
    const nudgesToRemove = length > ENTRIES_PER_PAGE ? length - ENTRIES_PER_PAGE : 0

    for (let i = 0; i < nudgesToRemove; i++) {
      this.nudgeTargets[this.nudgeTargets.length - 1].remove()
    }

    this.#toggleLoadMore(nudgesToRemove > 0)
  }

  async #disableNudgeTargetReactive(callback) {
    const nudgeTargetReactiveWas = this.#nudgeTargetReactiveEnabled
    this.#nudgeTargetReactiveEnabled = false

    await callback()

    this.#nudgeTargetReactiveEnabled = nudgeTargetReactiveWas
  }

  async #countAddedNudges(callback) {
    const nudgesBefore = this.nudgeTargets.length

    await callback()

    const nudgesAfter = this.nudgeTargets.length

    return nudgesAfter - nudgesBefore
  }

  #highestAgony() {
    return this.nudgeTargets[0]?.dataset?.agony
  }

  #lowestAgony() {
    const nudge = this.nudgeTargets[this.nudgeTargets.length - 1]

    return nudge?.dataset?.agony
  }

  #toggleLoadMore(show) {
    this.loadMoreTarget.classList.toggle("hidden", !show)
  }

  #scrollableContainer() {
    return this.element.closest(".page__flap")
  }
}
