import { Controller } from "@hotwired/stimulus"
import { Paginator } from "./messages/paginator"
import { RefreshManager } from "./messages/refresh_manager"
import { RenderContext } from "./messages/render_context"
import { ScrollManager } from "./messages/scroll_manager"
import { nextRenderTick } from "../helpers/dom_helpers"
import { renderTemplate } from "../helpers/template_helpers"
import { pageIsTurboPreview } from "../helpers/turbo_helpers"

/**
 * This is an exaustive list of all the behaviours in the ticket page.
 *
 * - Scroll to bottom on load
 * - Scroll to bottom when a message is sent *and* scrollbar was near end
 * - Indicate there are unseen messages when a message arrives and scroll bar is not autoscrolling (not near end).
 * - Load previous messages when scrolling up
 *   - Keep scrollbar position when previous messages are added above the scroll viewport.
 *   - Do not allow to load previous messages when there are no more messages to load.
 *   - Trim excess messages when scrolling back down to free memory/cpu usage
 * - When pending message takes too long to to be sent (no server ack), keep retrying to fetch the message.
 *   - if it fails because of network error, try again.
 *   - if it fails because of 404, mark as failed.
 *   - if it succeeds, replace the contents.
 * - On first websocket connection, call refreshManager#refresh.
 *   - Even though the user just loaded the page, things could have happened between loading the page
 *     and the client connecting to the websocket channel.
 * - On websocket reconnection, call refreshManager#refresh.
 * - When leaving the app in background, minimizing the browser or changing tabs (anything that triggers
 *   visibilitChange event, really) and coming back, call refreshManager#refresh if enough time has passed.
 *   Enough time here is around 1 minute. This is necessary mostly because browsers act different when page
 *   is in background to save battery.
 */
export default class extends Controller {
  static targets = [
    "feed",
    "loadPreviousPage",
    "message",
    "pendingMessageTemplate",
    "unreadMessagesOutsideScrollViewport",
  ]

  static values = {
    refreshUrl: String,
    fetchMessagesUrl: String,
  }

  static outlets = [ "composer" ]

  #scrollManager
  #paginator
  #renderContext
  #refreshManager

  initialize() {
    this.#scrollManager = new ScrollManager(this.feedTarget, {
      onAutoScrollingEnabled: () => {
        this.#hideUnreadMessagesOutsideScrollViewport()
      },
    })

    if (pageIsTurboPreview()) {
      return
    }

    this.#renderContext = new RenderContext()

    this.#refreshManager = new RefreshManager(this.refreshUrlValue)

    this.#paginator = new Paginator(
      this.feedTarget,
      this.loadPreviousPageTarget,
      this.fetchMessagesUrlValue,
      this.#renderContext,
    )
  }

  async connect() {
    await nextRenderTick()

    this.#scrollManager.scrollToBottom()
  }

  appendPendingMessage({ content, clientId }) {
    let html = renderTemplate(this.pendingMessageTemplateTarget.innerHTML, {
      content,
      clientId,
    })

    this.feedTarget.insertAdjacentHTML("beforeend", html)
  }

  realtimeConnect() {
    this.#renderContext.connected()
    this.#refreshManager.refresh("connect")
  }

  realtimeReconnect() {
    this.#refreshManager.refresh("reconnect")
  }

  realtimeReappear() {
    this.#refreshManager.refresh("reappear")
  }

  scrollToBottom() {
    this.#scrollManager.scrollToBottom()
  }

  autoScroll() {
    this.#scrollManager.autoScroll()
  }

  loadPreviousPage() {
    this.#paginator.loadPreviousPage()
  }

  messageTargetConnected(messageElm) {
    this.#refreshManager?.messageTargetConnected(messageElm)

    if (!this.#renderContext?.isStream()) {
      return
    }

    if (this.#scrollManager?.autoScroll()) {
      this.#paginator.trimExcessMessages()
    } else {
      this.#showUnreadMessagesOutsideScrollViewport()
    }
  }

  setQuottedMessage(elm) {
    const { messageDomId } = elm.currentTarget.dataset
    const messageElm = this.element.querySelector(`#${messageDomId}`)
    const messageId = messageElm.dataset.id
    const messageContent = messageElm.querySelector('.trix-content').outerHTML

    this.composerOutlet.setQuottedMessage({ messageId, messageContent })
  }

  #showUnreadMessagesOutsideScrollViewport() {
    this.unreadMessagesOutsideScrollViewportTarget.classList.remove("hidden")
  }

  #hideUnreadMessagesOutsideScrollViewport() {
    this.unreadMessagesOutsideScrollViewportTarget.classList.add("hidden")
  }
}
