import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link"]
  static classes = ["active"]

  connect() {
    this.update()
  }

  update() {
    this.linkTargets.forEach((link) => {
      const isActive = location.pathname.startsWith(link.pathname)

      link.classList.toggle(this.activeClass, isActive)
    })
  }
}
