import { Controller } from "@hotwired/stimulus"
import { parseHTML } from "../helpers/dom_helpers.js"
import { findIndices } from "../helpers/string_helpers"

const HIGHLIGHT_TAG_START  = `<span class="bg-yellow-100 text-yellow-900">`
const HIGHLIGHT_TAG_END    = `</span>`
const HIGHLIGHT_TAG_OFFSET = HIGHLIGHT_TAG_START.length + HIGHLIGHT_TAG_END.length

export default class extends Controller {
  static values = { term: String };

  connect() {
    if (! this.termValue) {
      return
    }

    const terms = this.termValue.toLowerCase().split(" ")

    for (const node of this.#textNodes()) {
      const text = node.nodeValue
      const taglistRange = new TaglistRange()

      let highlightedText = terms.reduce((text, term) => {
        return findIndices(text.toLowerCase(), term).reduce((text, index, loopCount) => {
          const offset = loopCount * HIGHLIGHT_TAG_OFFSET
          const sliceStart = index + offset
          const sliceEnd = index + offset + term.length
          const textToHighlight = text.slice(sliceStart, sliceEnd)

          taglistRange.push({ open: sliceStart, close: sliceEnd + HIGHLIGHT_TAG_OFFSET })

          return text.substring(0, sliceStart) + `${HIGHLIGHT_TAG_START}${textToHighlight}${HIGHLIGHT_TAG_END}` + text.substring(sliceEnd)
        }, text)
      }, text)

      const highlightTrim = node.parentElement.closest("[data-highlight-trim-length]")

      if (highlightedText !== undefined && highlightedText != text) {
        let trimLength = highlightTrim?.getAttribute("data-highlight-trim-length")

        if (trimLength) {
          trimLength = parseInt(trimLength)
          const halfTrimLength = Math.ceil(trimLength / 2)

          const firstHighlightIndex = highlightedText.indexOf(HIGHLIGHT_TAG_START)

          let highlightStart = taglistRange.lowerBound(firstHighlightIndex - halfTrimLength < 0 ? 0 : firstHighlightIndex - halfTrimLength)
          let highlightEnd = taglistRange.upperBound(firstHighlightIndex + 30 + halfTrimLength)

          const highlightRegionPrefix = highlightStart > 0 ? "..." : ""
          const highlightRegionSuffix = highlightEnd >= highlightedText.length ? "" : "..."

          if (highlightRegionSuffix === "") {
            // NOTE: since there was no trim at the end, we can allocate more space at the start
            highlightStart = taglistRange.lowerBound(firstHighlightIndex - trimLength < 0 ? 0 : firstHighlightIndex - trimLength)
          }

          if (highlightRegionPrefix === "") {
            // NOTE: since there was no trim at the start, we can allocate more space at the end
            highlightEnd = taglistRange.upperBound(firstHighlightIndex +  trimLength + HIGHLIGHT_TAG_OFFSET)
          }

          highlightedText = highlightRegionPrefix +  highlightedText.slice(highlightStart, highlightEnd) + highlightRegionSuffix
        }

        node.parentElement.replaceChild(parseHTML(highlightedText), node);
      } else if (highlightTrim) {
        const trimLength = parseInt(highlightTrim.getAttribute("data-highlight-trim-length"))
        const suffix = trimLength < text.length ? "..." : ""

        node.nodeValue = text.slice(0, trimLength) + suffix
      }
    }
  }

  #textNodes() {
    const textNodes = []

    const crawl = (element) => {
      if (element.nodeType === Node.TEXT_NODE) {
        textNodes.push(element)
      } else {
        for (const child of element.childNodes) {
          crawl(child)
        }
      }
    }

    for (const highlightableElement of this.element.querySelectorAll("[data-highlightable]")) {
      crawl(highlightableElement)
    }

    return textNodes
  }
}

class TaglistRange {
  constructor() {
    this.value = []
  }

  push({ open, close }) {
    for (const range of this.value) {
      if (close < range.open) {
        range.open += HIGHLIGHT_TAG_OFFSET
        range.close += HIGHLIGHT_TAG_OFFSET
      }
    }

    this.value.push({ open, close })
  }

  lowerBound(index) {
    for (const {open, close} of this.value) {
      if (index >= open && index < close) {
        return open
      }
    }

    return index
  }

  upperBound(index) {
    for (const {open, close} of this.value) {
      if (index >= open && index < close) {
        return close
      }
    }

    return index
  }

  // NOTE: leaving this here in case it helps in the future.
  debug(highlightedText) {
    for (const {open, close} of this.value) {
      console.log(highlightedText.slice(open, close))
    }
  }
}
