import "trix"
import "@rails/actiontext"
import "@hotwired/turbo-rails"
import { Application } from "@hotwired/stimulus"

const Telescope = window.Telescope = {}

/** COMPONENTS */

import "./components/spotlight"

/** MODELS **/

import { Notification } from "./models/notification"
Telescope.Notification = new Notification()

/** CONTROLLERS **/

export const application = Application.start()

import AutoRemovalController from "./controllers/auto_removal_controller"
application.register("auto-removal", AutoRemovalController)

import BrowserNotificationController from "./controllers/browser_notification_controller"
application.register("browser-notification", BrowserNotificationController)

import ClipboardController from "./controllers/clipboard_controller"
application.register("clipboard", ClipboardController)

import ComposerController from "./controllers/composer_controller"
application.register("composer", ComposerController)

import CurrentAccount from "./controllers/current_account_controller"
application.register("current-account", CurrentAccount)

import CurrentTicket from "./controllers/current_ticket_controller"
application.register("current-ticket", CurrentTicket)

import DatalistAutocompleteController from "./controllers/datalist_autocomplete_controller"
application.register("datalist-autocomplete", DatalistAutocompleteController)

import DropdownController from "./controllers/dropdown_controller"
application.register("dropdown", DropdownController)

import DynamicInputListController from "./controllers/dynamic_input_list_controller"
application.register("dynamic-input-list", DynamicInputListController)

import EmailReaderController from "./controllers/email_reader_controller"
application.register("email-reader", EmailReaderController)

import FacebookLoginController from "./controllers/facebook_login_controller"
application.register("facebook-login", FacebookLoginController)

import FormController from "./controllers/form_controller"
application.register("form", FormController)

import FrameController from "./controllers/frame_controller"
application.register("frame", FrameController)

import HighlightController from "./controllers/highlight_controller"
application.register("highlight", HighlightController)

import InputModelController from "./controllers/input_model_controller"
application.register("input-model", InputModelController)

import LocalDatetime from "./controllers/local_datetime_controller"
application.register("local-datetime", LocalDatetime)

import MessageDeliveryController from "./controllers/message_delivery_controller"
application.register("message-delivery", MessageDeliveryController)

import MessagesController from "./controllers/messages_controller"
application.register("messages", MessagesController)

import NavigationController from "./controllers/navigation_controller"
application.register("navigation", NavigationController)

import OneActiveAtATimeController from "./controllers/one_active_at_a_time_controller"
application.register("one-active-at-a-time", OneActiveAtATimeController)

import PasswordVisibilityController from "./controllers/password_visibility_controller"
application.register("password-visibility", PasswordVisibilityController)

import PendingMessageController from "./controllers/pending_message_controller"
application.register("pending-message", PendingMessageController)

import RealtimeController from "./controllers/realtime_controller"
application.register("realtime", RealtimeController)

import SoundNotificationController from "./controllers/sound_notification_controller"
application.register("sound-notification", SoundNotificationController)

import TimerController from "./controllers/timer_controller"
application.register("timer", TimerController)

import TicketPickingController from "./controllers/ticket_picking_controller"
application.register("ticket-picking", TicketPickingController)

import ToggleController from "./controllers/toggle_controller"
application.register("toggle", ToggleController)

import TurboController from "./controllers/turbo_controller"
application.register("turbo", TurboController)

import WebauthnController from "./controllers/webauthn_controller"
application.register("webauthn", WebauthnController)

import WizardController from "./controllers/wizard_controller"
application.register("wizard", WizardController)
