import { Controller } from "@hotwired/stimulus"
import { nextRenderTick } from "../helpers/dom_helpers"

// We want to make the iframe's height the same as the inner contents height. Otherwise, the user will
// see a scrollbar inside the iframe that displays the email content. This is bad.
//
// The way to fix this is to set the iframe's height to the body's scroll height. But for some weird reason
// we still get a scroll bar. We need to add this margin to kill the scroll bar. I'm not sure what's the
// correct number for this margin. I just guessed it.
const MARGIN_TO_AVOID_SCROLL_BAR_IN_PIXELS = 20

// Some emails have images embedded in them. This makes the `onload` event take a while to fire. A noticeable while.
// While the iframe's onload is not fired, we need to keep adjusting the iframe's height in short intervals to make
// it responsive. Otherwise the user will see a tiny iframe and then it will grow to the correct size after it finishes
// loading.
const INTERVAL_TO_ADJUST_HEIGHT_UNTIL_LOADED_IN_MILLISECONDS = 10

export default class extends Controller {
  static targets = ["iframe"]

  #loopCounter

  connect() {
    this.#loopCounter = 0

    this.#keepAdjustingHeightUntilLoaded()
  }

  toggleCarryover() {
    for (const carryover of this.iframeTarget.contentWindow.document.querySelectorAll('[data-composer=carryover]')) {
      const display = carryover.style.display
      carryover.style.display = display === "block" ? "none" : "block"
    }

    this.#adjustHeight()
  }

  #keepAdjustingHeightUntilLoaded() {
    this.#adjustHeight()

    this.#loopCounter += 1
    const nextCallDelay = INTERVAL_TO_ADJUST_HEIGHT_UNTIL_LOADED_IN_MILLISECONDS * this.loopCounter

    if (this.iframeTarget.dataset.iframeLoaded != 'true') {
      setTimeout(this.#keepAdjustingHeightUntilLoaded.bind(this), nextCallDelay)
    }
  }

  async #adjustHeight() {
    if (! this.iframeTarget.contentWindow.document.body) {
      await nextRenderTick()
    }

    const scrollHeight = this.iframeTarget.contentWindow.document.body.scrollHeight

    this.iframeTarget.style.height = scrollHeight + MARGIN_TO_AVOID_SCROLL_BAR_IN_PIXELS + "px"
  }
}
