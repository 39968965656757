import { Controller } from "@hotwired/stimulus"
import { MessageSender } from "./composer/message_sender"
import { Restrictions } from "./composer/restrictions"
import { Copilot } from "./composer/copilot"
import { renderTemplate } from "../helpers/template_helpers"
import { nextRenderTick } from "../helpers/dom_helpers"

export default class extends Controller {
  static targets = ["message", "attachment", "filelist", "filelistTemplate", "submit", "quottedMessage", "copilotCompletion", "restrictionError"]
  static classes = ["withAttachment", "withToolbar"]
  static outlets = ["messages"]
  static values = { restrictions: Object, copilotCompletionsUrl: String }

  #files
  #restrictions
  #copilot
  #quottedMessageId

  async connect() {
    this.#files = []

    await nextRenderTick() // wait trix toolbar to render so the attachmen

    this.#restrictions = new Restrictions({
      rules:            this.restrictionsValue,
      messageTarget:    this.messageTarget,
      attachmentTarget: this.attachmentTarget,
      errorTarget:      this.restrictionErrorTarget,
      submitTarget:     this.submitTarget
    })

    this.#copilot = new Copilot({
      trixEditor: this.messageTarget.editor,
      completionsUrl: this.copilotCompletionsUrlValue
    })

    if (this.hasCopilotCompletionTarget) {
      this.#copilot.completionGenerated(this.copilotCompletionTarget)
    }
  }

  async copilotCompletionTargetConnected(completionElm) {
    this.#copilot?.completionGenerated(completionElm)
  }

  applyRestrictions() {
    this.#restrictions.apply(this.messageTarget, this.#files)
  }

  toggleToolbar() {
    this.element.classList.toggle(this.withToolbarClass)
  }

  trixFileSelected(event) {
    const isInlineable = event.file.type.startsWith("image")
    const acceptsInlineAttachments = this.#restrictions.acceptsInlineAttachments()

    if (isInlineable && acceptsInlineAttachments) {
      return // let trix handle the image
    }

    event.preventDefault()
    event.stopPropagation()

    this.#files.push(event.file)
    this.#updateFilelist()
  }

  inputFileSelected(event) {
    for (const file of event.target.files) {
      this.#files.push(file)
    }

    event.target.value = null
    this.#updateFilelist()
  }

  removeFile(event) {
    const fileIndex = parseInt(event.currentTarget.dataset.fileIndex)

    this.#files.splice(fileIndex, 1)
    this.#updateFilelist()
  }

  setQuottedMessage({ messageId, messageContent }) {
    this.#quottedMessageId = messageId
    this.quottedMessageTarget.querySelector("[data-quotted-message-content]").innerHTML = messageContent
    this.quottedMessageTarget.classList.remove("hidden")
  }

  dismissQuottedMessage() {
    this.#quottedMessageId = null
    this.quottedMessageTarget.classList.add("hidden")
  }

  submit() {
    if (this.#restrictions.isInvalid()) {
      return
    }

    if (this.#copilot.isShowingCompletion()) {
      return
    }

    const clientId = this.#generateClientId()
    const content = this.messageTarget.value

    if (content.trim() === "" && this.#files.length === 0) {
      return
    }

    this.messagesOutlet.appendPendingMessage({ content, clientId })

    const sender = new MessageSender({
      url: this.element.action,
      content: this.messageTarget.value,
      files: this.#files,
      copilot: this.#copilot,
      quottedMessageId: this.#quottedMessageId,
      clientId
    })

    sender.send()

    this.element.reset()
    this.#files = []
    this.#updateFilelist()
    this.dismissQuottedMessage()
  }

  #updateFilelist() {
    const html = this.#files.map((file, index) => {
      return renderTemplate(this.filelistTemplateTarget.innerHTML, {
        filename: file.name,
        index: index,
      })
    })

    this.element.classList.toggle(this.withAttachmentClass, this.#files.length > 0)
    
    this.filelistTarget.innerHTML = html.join("")
    this.messagesOutlet.autoScroll()
    this.applyRestrictions()
  }

  #generateClientId() {
    return Math.random().toString(36).slice(2)
  }
}
