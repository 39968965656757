import { Controller } from "@hotwired/stimulus"

const HIDDEN_CLASS = "hidden"

export default class extends Controller {
  static values = { visible: String }

  connect() {
    if (this.hasVisibleValue) {
      if (this.visibleValue === "has-assignment" && this.#currentAssignmentId() && this.#currentAssignmentStatus() == "active") {
        this.element.classList.remove(HIDDEN_CLASS)
      }

      if (this.visibleValue === "composer-supports-quotted-message" && this.#getComposerValue("supports_quotted_message")) {
        this.element.classList.remove(HIDDEN_CLASS)
      }
    }
  }

  #currentAssignmentId() {
    return document.querySelector("meta[name=current-assignment-id]")?.content
  }

  #currentAssignmentStatus() {
    return document.querySelector("meta[name=current-assignment-status]")?.content
  }

  #getComposerValue(configName) {
    const content = document.querySelector("meta[name=current-composer]")?.content

    if (!content) {
      return null
    }

    const composer = JSON.parse(content)

    return composer[configName]
  }
}
